<template>
    <div class="page-exchange g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path:'/'}">我的积分筐</el-breadcrumb-item>
                <el-breadcrumb-item>确认积分订单</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="exchange">
                <div class="title">
                    <h3>收货地址</h3>
                    <a href="javascript:;" @click="showPickAddress = true">收货地址 >></a>
                </div>
                <div class="address-list">
                    <el-radio-group v-model="radio">
                        <div class="item" v-for="(item,index) in addressList" :key="index">
                            <div class="radio">
                                <el-radio :label="index">
                                    <p>{{ item.username }} {{ item.provinceName }} {{ item.cityName }} {{ item.districtName }} {{ item.address }} {{ item.phone }}</p>
                                </el-radio>
                            </div>
                            <div class="tools">
                                <span class="blue" @click="clickedit(item)" >编辑</span>
                                <span @click="showDel = true,addressId=item.id" class="red">删除</span>
                            </div>
                        </div>
                    </el-radio-group>
                </div>
                <div class="title">
                    <h3>商品清单</h3>
                </div>
                <div class="g-table-wrap">
                    <table class="g-table">
                        <thead>
                        <tr>
                            <td>商品</td>
                            <td>单价</td>
                            <td>数量</td>
                            <td>小计</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in orderJFGoodslist" :key="index">
                            <td>
                                <div class="det">
                                    <div class="pic">
                                        <img :src="item.pictureUrl" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>{{ item.name }}</h3>
                                        <p>产品编号：{{ item.number }}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="red2">{{ item.point }}积分</div>
                            </td>
                            <td>
                                {{item.count}}
                            </td>
                            <td>
                                <div class="red2">{{ item.point * item.count }}积分</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="total-count">
                    <p>
                        <span class="label">商品数量：</span>
                        <span class="con">{{orderJFGoodslist.length}}</span>
                    </p>
                    <p>
                        <span class="label">当前积分：</span>
                        <span class="con">{{customJFtotal}}</span>
                    </p>
                    <p>
                        <span class="label fwb">应付积分：</span>
                        <span class="con red">{{ totalprice }}</span>
                    </p>
                </div>
                <div class="btn-row">
                    <div class="g-btn" @click="sumbitorderInfo">立即兑换</div>
                </div>
            </div>
        </div>
        <pickAddress :provincelist="provincelist" :addressList="addressList" :addressid="goodsAddressInfo?goodsAddressInfo.id:goodsAddressInfo" v-if="showPickAddress" @ConfirmpickAddress="ConfirmpickAddress" @getaddressList="getaddressList" :JFradio="radio"/>
        <addAddress :provincelist="provincelist" :goodsAddressInfo="goodsAddressInfo" :isedit="iseditgoodsAddress" v-if="showAddAddress" @getaddressList="getaddressList" @close="showAddAddress = false" />
<!--        <pickAddress v-if="showPickAddress" @close="showPickAddress = false"-->
<!--                     @addAddress="showAddAddress = true;showPickAddress = false"/>-->
<!--        <addAddress v-if="showAddAddress" @close="showAddAddress = false"/>-->
        <delPop v-if="showDel" @close="showDel = false" @confirm="deleteAddress"/>
    </div>
</template>

<script>
import pickAddress from "@/components/pickAddress.vue";
import addAddress from "@/components/addAddress.vue";
import delPop from "@/components/delPop.vue";
import {orderInfoSave} from "@/api/transaction";
import {Addressdelete, addressList, getregionList} from "@/api/account";
import {customerJFGoodsList,orderIntegralSave} from "@/api/Product";

export default {
    name: "exchange",
    components: {pickAddress, addAddress, delPop},
    data() {
        return {
            radio: 0,
            showPickAddress: false,
            showAddAddress: false,
            showDel: false,

            provincelist:[], // 行政区划
            addressList:[], // 收货地址列表
            goodsAddressInfo:"", // 收货地址
            iseditgoodsAddress:false, // 是否编辑收货地址

            orderJFGoodslist:[], // 积分商品列表
            totalprice:0,
            totalnum:0,
            customJFtotal:0, // 用户积分总额
        }
    },
    watch:{
        radio(n,o){
            this.goodsAddressInfo=this.addressList[n]
        }
    },
    mounted() {
        console.log(JSON.parse(localStorage.getItem('customInfo')))
        this.customJFtotal=JSON.parse(localStorage.getItem('customInfo')).integralTotal
        this.getCity() // 获取行政区划
        this.getaddressList() // 获取地址列表
        if(this.$route.query){
            console.log(this.$route.query.goodsIdlist)
            this.goodsIdlist=this.$route.query.goodsIdlist
            this.goodsNums=this.$route.query.goodsNums
            this.goodsNums=this.goodsNums.split(',')
            this.getcustomerJFGoodsList()
        }
    },
    methods: {
        // 立即兑换
        jumpTo: function () {
            this.$router.push('/IntegralMall/Result')
        },
        // 确认收货地址
        ConfirmpickAddress(e) {
            console.log(e)
            if(e){ // 确认选择地址
                // this.goodsAddressInfo=e
                this.addressList.forEach((i,index)=>{
                    if(i.id==e.id){
                        this.radio=index
                    }
                })
            }
            this.showPickAddress = false
        },
        // 点击编辑
        clickedit(item){
            this.addressId=item.id
            this.iseditgoodsAddress=true
            this.showAddAddress = true
            this.goodsAddressInfo=item
        },
        // 提示
        message(type,title){
            this.$message({
                type: type,
                message: title,
                duration: 2000
            });
        },
        //  -------------------------接口请求
        // 提交商品信息
        sumbitorderInfo(){

            let goodsList= []
            this.orderJFGoodslist.forEach(i=>{
                let info= {
                    "points": i.point * i.count, // 商品积分
                    "integralGoodsId": i.id, // 商品id
                    "quantityPurchase":i.count  // 商品数量
                }
                goodsList.push(info)
            })
            let params={
                customerId:localStorage.getItem('customId'), // 客户id
                integralPoint:this.totalprice, // 订单总积分
                deliveryAddress:this.goodsAddressInfo.address, // 收货-详细地址
                deliveryPhone:this.goodsAddressInfo.phone, // 收货-电话
                deliveryRegionCode:this.goodsAddressInfo.regionCode, // 收货-区域代号
                deliveryUsername:this.goodsAddressInfo.username, // 收货-姓名
                "goodsList": goodsList, // 商品信息

            }
            console.log('提交订单参数',params)
            orderIntegralSave(params).then((res) => {
                if (res.data.code==200) {
                    let data=res.data.data
                    console.log(data)
                    this.$router.push('/IntegralMall/Result')
                    // this.message('success','提交订单成功')
                }else{
                    this.message('error',res.data.mag)
                }

            })


        },


        // 获取行政区划
        getCity(){
	        console.log(this.$lazylist)
	        this.provincelist=this.$lazylist
            // getregionList({}).then((res) => {
            //     if (res.data.code==200) {
            //         let data=res.data.data.slice(1)
            //         console.log(data)
            //         this.provincelist=data
            //     }
            // })
        },
        // 获取收货地址列表
        getaddressList(){
            console.log('获取收货地址列表')
            let customerId=localStorage.getItem('customId')
            addressList({customerId:customerId}).then((res) => {
                if (res.data.code==200 && res.data.data.length>0) {
                    this.addressList=res.data.data
                    let radio=0
                    this.addressList.forEach((i,index)=>{
                        let array=[]
                        // console.log(i.regionName)
                        array=i.regionName.split('-')
                        i.provinceName=array[0]
                        i.cityName=array[1]
                        i.districtName=array[2]
                        console.log(array)
                        if(this.radio==index){
                            radio=index
                        }
                    })
                    this.radio=radio
                    this.goodsAddressInfo=this.addressList[radio]
                }else{
                    this.radio=0
                    this.addressList=[]
                    this.goodsAddressInfo=''
                }

            })
        },
        // 删除收货地址
        deleteAddress(){
            console.log(this.addressId)
            Addressdelete({ids:this.addressId}).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "删除成功",
                        duration: 2000
                    });
                    this.getaddressList() // 重新获取地址列表
                    // this.$emit('getaddressList') // 重新获取地址列表
                    this.showDel=false
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
                this.addressId=''

            })
        },
    //    批量获取积分商品
        getcustomerJFGoodsList(){
            let parmas={
                customerId:localStorage.getItem('customId'),
                goodsIdList:this.goodsIdlist
            }
            customerJFGoodsList(parmas).then((res) => {
                if (res.data.code==200) {
                    let data=res.data.data
                    console.log(data)
                    let totalprice=0
                    data.forEach((i,index)=>{
                        i.describeText=this.$utils.showHtml(i.describeText)
                        i.count=this.goodsNums[index]
                        totalprice+=Number(i.point) * i.count
                    })
                    this.totalprice=totalprice
                    this.orderJFGoodslist=data
                }
            })
        },


    },
}
</script>

<style scoped>
.g-table tbody .det{
    justify-content: center;
}
.g-table tbody .det .text{
    flex:0 0 auto
}
</style>
